import React from "react";

export interface Props {
  name: string;
  type: "CUSTOM" | "SIMULATION" | "CAPITOLI" | undefined | string;
  isSelected: boolean;
  answer: boolean;
  isAnswered: boolean;
  showResult: boolean;
  onClick: () => void;
}

export const QuestionBtn: React.FC<Props> = (props) => {
  const { name, type, isSelected, answer, showResult, isAnswered, onClick } =
    props;


  const getStyleClass = () => {

    let className = "";

    if (showResult) {
      className =
        isAnswered === answer
          ? ("right_answer")
          : "wrong_answer";
    } else if (type === "SIMULATION" && isAnswered !== undefined) {
      className = ("answerd");
    }
    return className;

    // let className = "";

    // if (isAnswered !== null) {
    //   className = "right_answer";s
    // }
    // if (showResult) {
    //   className = "wrong_answer";
    // }
  };

  // console.log("showResult", showResult);
  return (
    <span
      className={`${getStyleClass()} ${isSelected && "focused"}`}
      onClick={onClick}
      // isSelected && {color: Colors.red}
    >
      {name}
    </span>
  );
};

export default QuestionBtn;
