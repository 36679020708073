import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useCountdown } from "usehooks-ts";

/**
 * This function returns the {@link TimerView} `component`
 *
 * @param props recieve `Props`
 * @returns `Element`
 */

export interface Props {
  onTimerEnd: () => void;
  ref: any;
}

export type TimerViewRef = {
  startTimer: () => void;
  stopTimer: () => void;
  resetTimer: () => void;
};

export const TimerView: React.FC<Props> = forwardRef((props, ref) => {
  const { onTimerEnd } = props;
  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: 60 * 20,
      intervalMs: 1000,
    });
  const [timerOn, setTimerOn] = useState(true);

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    startTimer() {
      if (timerOn) {
        startCountdown();
      }
    },
    stopTimer() {
      stopCountdown();
    },
    resetTimer() {
      resetCountdown();
    },
  }));

  useEffect(() => {
    // console.log("count", count);
    if (count === 0) {
      onTimerEnd();
    }
  }, [count]);

  let minutes = Math.floor(count / 60);
  return (
    <div className="tempo_box">
      {/* <div className="tempo_btn">
        <span>Tempo</span>
      </div> */}
      <div className="tempo">
        <span>TEMPO A DISPOSIZIONE</span>
        <span>{`${minutes}:${count - minutes * 60}`}</span>
      </div>
    </div>
  );
});

export default TimerView;
