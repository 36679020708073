import React from "react";
import Urls from "../../api/Urls";
import { FaCheckSquare } from "react-icons/fa";

export interface Props {
  questions: any[];
  isExamClosed: boolean;
  type: "CUSTOM" | "SIMULATION" | "CAPITOLI" | string | undefined;
}

export const ShowAllQuestion: React.FC<Props> = (props) => {
  const { type, questions, isExamClosed } = props;


  const getCheckIconColor = (item: any) => {
    let iconColor = "blue";

    if (type == "SIMULATION" && isExamClosed) {
      iconColor = item.user_answer == item.answer ? "green" : "red";
    } else if (type != "SIMULATION" && item.user_answer != undefined) {
      iconColor = item.user_answer == item.answer ? "green" : "red";
    }

    return iconColor;
  };

  const getSolutionView = (item: any) => {
    let shouldShow = false;
    if (type == "SIMULATION") {
      shouldShow = isExamClosed && item.user_answer != item.answer;
    } else {
      shouldShow =
        item.user_answer != undefined && item.user_answer != item.answer;
    }

    return shouldShow && item?.question_solution ? (
      <div dangerouslySetInnerHTML={{ __html: item.question_solution }} />
    ) : null;
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="ques">
          <div className="ques_item ques_index">
            <span>{index + 1}</span>
            {item.image && <img src={Urls.storagePath + item.image} alt="" />}
          </div>
          <div className="ques_item">
            <span className="ques_title">Testo della domanda</span>
            <span className="the_ques">{item.question}</span>
            <span className="the_ques">{getSolutionView(item)}</span>
          </div>
          <div className="ques_item ques_result">
            <span>V</span>
            <div className={`ans ${getCheckIconColor(item)}`}>
              {item.user_answer === true && <FaCheckSquare />}
            </div>
          </div>
          <div className="ques_item ques_result">
            <span>F</span>
            <div className={`ans ${getCheckIconColor(item)}`}>
              {item.user_answer === false && <FaCheckSquare />}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShowAllQuestion;
