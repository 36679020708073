import Client, {ClientOption} from '../client';

export default {
  // returns list of all the Insurances route
  login: (params: any, options?: ClientOption) =>
    Client('POST', 'login', params, options),

  register: (params: any, options?: ClientOption) =>
    Client('POST', 'user', params, options),

  forgotPassword: (params: any, options?: ClientOption) =>
    Client('POST', 'forgot-password', params, options),

  resetPassword: (params: any, options?: ClientOption) =>
    Client('POST', 'reset-password', params, options),

  getProfile: (options?: ClientOption) =>
    Client('GET', 'profile', undefined, options),

  uploadProfile: (params: any, options?: ClientOption) =>
    Client('POST', 'update-profile', params, options),

  uploadProfilePic: (formdata: FormData, options?: ClientOption) =>
    Client('FILE', 'update-profile-pic', formdata, options),

  logout: (options?: ClientOption) => Client('POST', 'logout', {}, options),
};
