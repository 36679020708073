import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface UserState {
  chapters: any[];
  setChapters: (user: any[]) => void;
  sections: any[];
  setSections: (user: any[]) => void;
  originalQuizList: any[];
  setOriginalQuizList: (user: any[]) => void;
  quizList: any[];
  setQuizList: (quizList: any[]) => void;
  capitoliIds: number[];
  setCapitoliIds: (capitoliIds: number[]) => void;
}

const useCourseStore = create<UserState>()(
  devtools((set, get) => ({
    chapters: [],
    setChapters: (chapters) => set(() => ({ chapters: chapters })),
    sections: [],
    setSections: (sections) => set(() => ({ sections: sections })),
    originalQuizList: [],
    setOriginalQuizList: (originalQuizList) =>
      set(() => ({ originalQuizList: originalQuizList })),
    quizList: [],
    setQuizList: (quizList) => set(() => ({ quizList: quizList })),
    capitoliIds: [],
    setCapitoliIds: (capitoliIds) => set(() => ({ capitoliIds: capitoliIds })),
  }))
);

export default useCourseStore;
