import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface AppState {
  isOnline: boolean;
  setIsOnline: (newState: boolean) => void;
  indicator: boolean;
  showIndicator: () => void;
  hideIndicator: () => void;
  translateText: string;
  setTranslateText: (newState: string) => void;
  showTranslatePopover: boolean;
  setShowTranslatePopover: (newState: boolean) => void;
  showActivityIndicator: boolean;
  setShowActivityIndicator: (newState: boolean) => void;
}

const useAppStore = create<AppState>()(
  devtools((set) => ({
    isOnline: false,
    setIsOnline: (newState) => set((state) => ({ isOnline: newState })),
    indicator: false,
    showIndicator: () => set((state) => ({ indicator: true })),
    hideIndicator: () => set((state) => ({ indicator: false })),
    translateText: "",
    setTranslateText: (newState) =>
      set((state) => ({ translateText: newState })),
    showTranslatePopover: false,
    setShowTranslatePopover: (newState) =>
      set((state) => ({ showTranslatePopover: newState })),
    showActivityIndicator: false,
    setShowActivityIndicator: (newState) =>
      set((state) => ({ showActivityIndicator: newState })),
  }))
);

export default useAppStore;
