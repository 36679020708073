import { unstable_batchedUpdates } from "react-dom";
//@ts-ignore
import countries from "react-select-country-list";
import useUserStore from "../stores/useUserStore";
import useAppStore from "../stores/useAppStore";

const countryList = countries().getData();

interface Word {
  type: "word";
  content: string;
}

interface LineBreak {
  type: "lineBreak";
  content: string;
}

export type TextElement = Word | LineBreak;

const translateApi = async (text: string, toLang: "bn" | "en") => {
  const response = await fetch(
    `https://api.mymemory.translated.net/get?q=${encodeURIComponent(
      text
    )}&langpair=it|${toLang}`
  );

  const data = await response.json();
  const translatedText = data.responseData.translatedText;

  return translatedText;
};

const Utils = {
  getFullName: (firstName: string, lastName: string) => {
    let fullName = "";
    if (firstName) {
      fullName = firstName;
    }
    if (lastName) {
      fullName = fullName + " " + lastName;
    }
    return fullName;
  },

  getCountryNameByCountryCode: (countryCode: string) => {
    const country = countryList.find((item: any) => item.value === countryCode);
    if (country) {
      return country.label;
    }
    return "";
  },

  saveUserCreadential: (
    userData: any,
    accessToken: string,
    isLogIn: boolean
  ) => {
    const userInfo = JSON.stringify({
      userData: userData,
      accessToken: accessToken,
      isLogIn: isLogIn,
    });

    try {
      localStorage.setItem("appInfo", userInfo);
    } catch (err) {
      console.log("Could not save credentials, " + err);
    }

    unstable_batchedUpdates(() =>
      useUserStore.getState().setIsLogedIn(isLogIn)
    );
    unstable_batchedUpdates(() => useUserStore.getState().updateUser(userData));
    return true;
  },

  translateApi: translateApi,

  translateFromApi: async (text: string, toLang: "bn" | "en") => {
    if (text?.length > 0) {
      unstable_batchedUpdates(() =>
        useAppStore.getState().setShowActivityIndicator(true)
      );
      const result = await translateApi(text, toLang);

      // console.log("result", result.toString());
      unstable_batchedUpdates(() => {
        useAppStore.getState().setShowActivityIndicator(false);
        useAppStore.getState().setTranslateText(result.toString());
        useAppStore.getState().setShowTranslatePopover(true);
      });
    }
  },

  splitWordsWithLineBreaks: (text: string): TextElement[] => {
    const wordsAndBreaks = text?.split(/(\s+)/); // Split by any whitespace character and preserve them
    const result: TextElement[] = [];

    wordsAndBreaks.forEach((wordOrBreak) => {
      if (wordOrBreak.includes("\n")) {
        result.push({ type: "lineBreak", content: wordOrBreak }); // Preserve line breaks
      } else if (wordOrBreak != " ") {
        result.push({ type: "word", content: wordOrBreak });
      }
    });

    return result;
  },

  shuffle(array: any[]): any[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  },
};

export default Utils;
