const domain = "https://manik.lmpatente.com/";
const domainAudio = "https://manik.lmpatente.com";
// const domain = "http://127.0.0.1:8000/";
const baseUrl = domain + "api/";

const Urls = {
  domain: domain,
  domainAudio: domainAudio,
  baseUrl: baseUrl,
  storagePath: domain + "storage/",
};

export default Urls;
