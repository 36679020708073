import React from "react";

const WharWeDo = () => {
  return (
    <div className="Container">
      <div className="functtionality_main">
        <div className="functionality">
          <h1>Free Driving Education:</h1>
          <p>
            We provide comprehensive educational resources, lessons, and
            materials to help individuals understand the rules of the road,
            traffic signals, and other essential driving concepts.
          </p>
          <h1>Practice Tests:</h1>

          <p>
            Our platform offers free access to practice tests, allowing learners
            to assess their knowledge and readiness for real driving situations.
          </p>
          <h1>Community Support:</h1>
          <p>
            We foster a supportive community where learners can connect, share
            experiences, and seek advice from experienced drivers. Our forums
            and discussion groups are valuable resources for individuals on
            their journey to becoming competent drivers.
          </p>
          <h1>Ability to review previous modules:</h1>
          <p>
            Our online drivers’ education course is designed so that the
            students can go back and review previous classroom modules at any
            point during the program should they have questions or wish to
            refresh certain sections.
          </p>
          <h1>Guidance for License Procedures:</h1>
          <p>
            We assist users in understanding the procedures and requirements for
            obtaining a driver's license, offering guidance on the necessary
            steps to follow. Safety Tips and Information: We provide tips on
            safe driving practices, road safety, and the importance of adhering
            to traffic regulations. As a non-profit free driving coaching
            center, our primary focus is on making driving education accessible
            to all, promoting responsible driving behavior, and contributing to
            overall road safety within our community.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WharWeDo;
