import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface UserState {
  user: any;
  updateUser: (user: any) => void;
  removeUser: () => void;
  isLogedIn: boolean;
  setIsLogedIn: (newState: boolean) => void;
  patenteLessons: any;
  setPatenteLessons: (newState: any) => void;
}

const useUserStore = create<UserState>()(
  devtools((set, get) => ({
    user: {},
    updateUser: (user) => set(() => ({ user: user })),
    removeUser: () => set(() => ({ user: {} })),
    isLogedIn: false,
    setIsLogedIn: (newState) => set((state) => ({ isLogedIn: newState })),
    patenteLessons: {
      id: 0,
      description: "",
      name: "",
      price: 0,
      user_has_course: false,
      lessons: [],
    },
    setPatenteLessons: (newState) =>
      set((state) => ({ patenteLessons: newState })),
  }))
);

export default useUserStore;
