import React, { useState } from "react";
import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import UserApi from "../api/services/UserApi";
import { errorToast, infoToast, successToast } from "../utils/Toasts";

const Signup = () => {
  const navigate = useNavigate();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateInput = (type: string, value: string) => {
    switch (type) {
      case "fullName":
        return value.trim() !== "" ? "" : "Full name is required";
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value) ? "" : "Invalid email address";
      case "password":
        const passwordRegex =
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d@$.!%*#?&]{8,}$/;
        return passwordRegex.test(value)
          ? ""
          : "Password must be 8 characters, at least one uppercase and lowercase letter and digit";
      case "phoneNumber":
        return value.trim() !== "" ? "" : "Phone number is required";
      default:
        return "";
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const newErrors: { [key: string]: string } = {};

    const inputData: { [key: string]: string } = {
      fullName,
      email,
      password,
      phoneNumber: phoneNumber || "",
    };

    Object.keys(inputData).forEach((type) => {
      const errorMessage = validateInput(type, inputData[type]);
      if (errorMessage) {
        newErrors[type] = errorMessage;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const params = {
      name: fullName,
      email: email,
      password: password,
      phone_number: phoneNumber,
    };

    UserApi.register(params, {
      onSuccess: (response) => {
        successToast(
          "You have successfully registered an account. Please login now."
        );
        navigate("/login");
      },
      onError: (error) => {
        console.log("error", error);
        errorToast("An error occurred while registering. Please try again.");
      },
    });
  };

  return (
    <div className="Container pb-4">
      <div className="login_main">
        <form className="login_form" onSubmit={handleSubmit}>
          <div className="logo">
            <img src={logo} alt="logo" />
            <span>Sign In</span>
          </div>

          <div className="inputs w-100">
            <div className="input">
              <label>Full Name</label>
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Enter Your Full Name"
                // required
              />
              {errors?.email && (
                <span className="error">{errors?.fullName}</span>
              )}
            </div>
            <div className="input">
              <label>Phone Number</label>
              <PhoneInput
                international
                defaultCountry="US"
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
              />
              {errors?.phoneNumber && (
                <span className="error">{errors?.phoneNumber}</span>
              )}
            </div>
            <div className="input">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                // required
              />
              {errors?.email && <span className="error">{errors?.email}</span>}
            </div>
            <div className="input">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                // required
              />
              {errors?.password && (
                <span className="error">{errors?.password}</span>
              )}
            </div>
          </div>
          <div className="policy">
            <p>
              By logging, you agree to our{" "}
              <Link to="/">Terms & Conditions</Link> and{" "}
              <Link to="/">Privacy Policy</Link>.
            </p>
          </div>
          <div className="buttons w-100">
            <button type="submit" className="btnr primary w-100">
              Log in
            </button>
            <Link to="/login" className="btnr secoundary w-100">
              Already have Account !
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
