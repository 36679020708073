import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';

interface ZoomModalProps {
    children: React.ReactNode;
    img: string;
}

const ZoomModal: React.FC<ZoomModalProps> = ({children, img }) => {

    const imgRef = useRef<HTMLImageElement>(null!);

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (imgRef.current) {
            // const { left, top, width, height } = imgRef.current.getBoundingClientRect();
            // const x = ((e.clientX - left) / width) * 100;
            // const y = ((e.clientY - top) / height) * 100;
            // imgRef.current.style.transformOrigin = `${x}% ${y}%`;
            // imgRef.current.style.transform = 'scale(2)'; 
        }
    };

    const handleMouseLeave = () => {
        if (imgRef.current) {
            imgRef.current.style.transform = 'scale(1)';
        }
    };

    const handleClose = () => setShow(false);


    const [show, setShow] = React.useState(false);

    const handleImageClick = () => setShow(true);

    return (
        <>
            <div onClick={handleImageClick}>
                {children}
            </div>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show} onHide={handleClose}
                className="zoomModal"
            >
                <Modal.Body>
                    <div
                        className="zoomContainer"
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                    >
                        {img && <img ref={imgRef} src={img} alt="modal" />}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btnr primary" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ZoomModal;
