import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import UserApi from "../api/services/UserApi";
import { errorToast, successToast } from "../utils/Toasts";
import { Carousel } from "react-bootstrap";
import BackButton from "../components/microComponents/BackButton";

const Forget = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [password, setPassword] = useState({
    pass: "",
    confirmPass: "",
  });

  const onSendOtoPressed = (e: any) => {
    e.preventDefault();

    if (email.length <= 0) {
      return errorToast("Please enter Email");
    }

    if (activeIndex === 0) {
      UserApi.forgotPassword(
        { email: email },
        {
          onSuccess: (response) => {
            setActiveIndex(1);
          },
        }
      );
    } else if (activeIndex === 1) {
      if (password.pass != password.confirmPass) {
        return errorToast("Password Dont Match");
      }

      const params = {
        email: email,
        verification_code: code,
        password: password.pass,
        password_confirmation: password.confirmPass,
      };

      UserApi.resetPassword(params, {
        // showIndicator: true,
        onSuccess: (response) => {
          console.log("response", response);
          successToast(response.message);
          navigate("/login");
        },
      });
    }
  };

  return (
    <div className="Container pb-4">
      <div className="forget_main">
        <BackButton title={"Login"} isSticky={true}/>

        <form className="login_form" onSubmit={onSendOtoPressed}>
          <div className="logo">
            <img src={logo} alt="logo" />
            <span>Reset Password</span>
          </div>
          <Carousel
            className="w-100"
            controls={false}
            indicators={false}
            interval={null}
            activeIndex={activeIndex}
            onSelect={(index) => setActiveIndex(index)}
          >
            <Carousel.Item>
              <div className="inputs w-100">
                <div className="input">
                  <label>Email Address</label>
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your Email"
                  />
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="inputs w-100">
                <div className="input">
                  <label>Varify OPT</label>
                  <input
                    type="number"
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Enter your OTP"
                  />
                </div>
                <div className="input">
                  <label>Password</label>
                  <input
                    type="password"
                    onChange={(e) =>
                      setPassword({ ...password, pass: e.target.value })
                    }
                    placeholder="Enter your Address"
                  />
                </div>
                <div className="input">
                  <label>Confirm Pass</label>
                  <input
                    type="password"
                    onChange={(e) =>
                      setPassword({ ...password, confirmPass: e.target.value })
                    }
                    placeholder="Enter your Address"
                  />
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
          <div className="buttons w-100">
            <button type="submit" className="btnr primary w-100">
              {activeIndex === 0 ? "Send OTP" : "Reset Password"}
            </button>
            {/* <Link to="/login" className="btnr secoundary w-100">
              Sign In
            </Link> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forget;
