import React from "react";
import { Modal } from "react-bootstrap";
import Urls from "../../api/Urls";
// import useAppStore from "../../stores/useAppStore";

const ModalReact = (props: any) => {
  // const translateText = useAppStore((state) => state.translateText);

  // console.log("imageUrl", imageUrl);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="translationModal"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}

      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: props?.translate }} />
        <div className="modal_image">
          {props?.imageUrl && props?.imageUrl != "" && (
            <img
              className="img"
              src={Urls.storagePath + props.imageUrl}
              alt=""
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btnr sm warning" onClick={props.onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalReact;
