import { useNavigate } from "react-router-dom";
import Utils from "../../utils/Utils";
import Urls from "../../api/Urls";
import QuestionSpan from "../QuestionSpan/QuestionSpan";
import React from "react";
import { FaArrowsAlt } from "react-icons/fa";
import ZoomModal from "../microComponents/ZoomModal";

export interface Props {
  title: string;
  desc: string;
  groups?: any[];
  mainImages?: any;
  optionalImages?: any[];
}

/**
 * This function returns the {@link SectionCard} `component`
 *
 * @param props recieve `Props`
 * @returns `Element`
 */
export const SectionCard: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { title, desc, groups, mainImages, optionalImages } = props;

  // console.log("mainImages", mainImages);
  return (
    <div className="trafficoItem">
      <div className="header">
        <div className="title">{title}</div>
        <div className="subSection">
          {mainImages && (
            <ZoomModal img={Urls.storagePath + mainImages?.file_path}>
              <div className="mainImage">
                <img src={Urls.storagePath + mainImages?.file_path} alt="" />
                {mainImages?.fig_num && (
                  <h6 style={{ textAlign: "center" }}>
                    Fig: {mainImages?.fig_num}
                  </h6>
                )}
              </div>
            </ZoomModal>
          )}
          <div className="buttons">
            <button
              onClick={() => Utils.translateFromApi(desc, "bn")}
              className="btnr sm warning"
            >
              বাংলা
            </button>
            <button
              onClick={() => Utils.translateFromApi(desc, "en")}
              className="btnr sm blue"
            >
              English
            </button>
          </div>
        </div>
      </div>
      <div className="info">
        {Utils.splitWordsWithLineBreaks(desc).map((item, index) => (
          <span className="question" key={index}>
            {item.type === "lineBreak" ? (
              item.content
                .match(/\n/g)
                ?.map((line, index) => <br key={index} />)
            ) : (
              <QuestionSpan question={item.content} />
            )}
          </span>
        ))}
      </div>

      {groups && (
        <div className="groupBtn">
          {groups.map((item) => (
            <button
              key={item.id}
              onClick={() => navigate(`/Quizzes/${item.id}/${title}`)}
              className="btnr sm blue"
            >
              {item.group_name}
            </button>
          ))}
        </div>
      )}

      <div className="imageWrapper">
        {optionalImages &&
          optionalImages.length > 0 &&
          optionalImages?.map((item, index) => (
            <div key={index} className="img">
              <ZoomModal img={Urls.storagePath + item.file_path}>
                <img src={Urls.storagePath + item.file_path} alt={item.alt} />
                <div className="expand-icon">
                  <FaArrowsAlt />
                </div>
              </ZoomModal>
              {item?.fig_num && (
                <h6 style={{ textAlign: "center" }}>Fig: {item?.fig_num}</h6>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SectionCard;
