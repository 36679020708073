import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import UserApi from "../api/services/UserApi";
import { successToast } from "../utils/Toasts";
import Utils from "../utils/Utils";
import useUserStore from "../stores/useUserStore";

const Login = () => {
  const navigate = useNavigate();
  const isLogedIn = useUserStore((state) => state.isLogedIn);
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (isLogedIn) {
      navigate("/");
    }
  }, []);

  const validateInput = (type: string, value: string) => {
    switch (type) {
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value) ? "" : "Invalid email address";
      case "password":
        return value.trim() ? "" : "Password is required";
      default:
        return "";
    }
  };

  const userLogin = (e: any) => {
    e.preventDefault();
    const newErrors: { [key: string]: string } = {};

    ["email", "password"].forEach((type) => {
      const errorMessage = validateInput(
        type,
        type === "email" ? email : password
      );
      if (errorMessage) {
        newErrors[type] = errorMessage;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    UserApi.login(
      {
        email: email,
        password: password,
      },
      {
        onSuccess: (response) => {
          window.accessToken = response.access_token;
          Utils.saveUserCreadential(response.data, response.access_token, true);
          successToast(response.message);
          navigate("/");
        },
        onError: (error) => {
          console.log("error", error);
        },
      }
    );
  };

  // console.log("email", email);
  return (
    <div className="Container pb-4">
      <div className="login_main">
        <form className="login_form" onSubmit={userLogin}>
          <div className="logo">
            <img src={logo} alt="logo" />
            <span>Sign In</span>
          </div>
          <div className="inputs w-100">
            <div className="input">
              <label>Email Address</label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your Email"
              />
              {errors?.email && <span className="error">{errors?.email}</span>}
            </div>
            <div className="input">
              <label>Password</label>
              <input
                type="password"
                onChange={(e) => setPass(e.target.value)}
                placeholder="Enter your password"
              />
              {errors?.password && (
                <span className="error">{errors?.password}</span>
              )}
            </div>
          </div>
          <div className="policy">
            <Link className="forget" to="/forget">
              Forget Password
            </Link>
          </div>
          <div className="policy">
            <p>
              By logging, you agree to our{" "}
              <Link to="/">Terms & Conditions</Link> and{" "}
              <Link to="/">Privacy Policy</Link>.
            </p>
          </div>
          <div className="buttons w-100">
            <button type="submit" className="btnr primary w-100">
              Log in
            </button>
            <Link to="/signup" className="btnr secoundary w-100">
              Sign up as new member
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
