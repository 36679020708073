import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../Pages/Footer";

const Main = () => {
  const location = useLocation();

  const getRoute = () => {
    if (
      location.pathname === "/" ||
      location.pathname === "/login" ||
      location.pathname === "/quizExam/SIMULATION"
    ) {
      return "bg";
    } else {
      return "bg_green";
    }
  };

  return (
    <div className={`bg main_body ${getRoute()}`}>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Main;
