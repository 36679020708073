import React, { useEffect, useState } from "react";
import BackButton from "../components/microComponents/BackButton";
import { useLocation, useParams } from "react-router-dom";
import CourseApi from "../api/services/CourseApi";
import SectionCard from "../components/SectionCard/SectionCard";
import Skeleton from "react-loading-skeleton";
import scrollToTop from "../components/ScrollTop/ScrollTop";

const skeleton = (
  <>
    {Array.from({ length: 1 }).map((_, index) => (
      <div className="trafficoItem skeleton">
        <div className="header">
          <div className="title">
            <Skeleton width={200} height={30} />
          </div>
          <div className="subSection">
            <div className="mainImage">
              <Skeleton width={200} height={20} />
              <Skeleton width={200} height={20} />
            </div>
          </div>
        </div>
        <div className="info">
          <Skeleton count={4} />
          <Skeleton width="50%" height={20} />
        </div>

        <div className="groupBtn">
          <Skeleton width={100} height={30} />

          <Skeleton width={100} height={30} />
        </div>
      </div>
    ))}
  </>
);

const SectionDetails = () => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [section, setSection] = useState<any>({});

  useEffect(() => {
    scrollToTop();
    getSection();
  }, []);

  const getSection = () => {
    setIsLoading(true);
    CourseApi.getSectionDetails(Number(id), {
      onSuccess: (response) => {
        // console.log("response", response);
        setIsLoading(false);
        setSection(response.data);
      },
      onError: () => setIsLoading(false),
    });
  };

  // console.log("section", section);
  return (
    <div className="Container pb-4">
      <div className="trafficoMain">
        <BackButton title={location.state?.chapterName} isSticky={true} />

        {isLoading ? (
          skeleton
        ) : (
          <SectionCard
            key={section?.id}
            title={section?.section_title}
            desc={section?.section_desc}
            groups={section?.groups}
            mainImages={
              section?.mainMedia?.length > 0 ? section?.mainMedia[0] : undefined
            }
            optionalImages={section?.optionalMedia}
          />
        )}
      </div>
    </div>
  );
};

export default SectionDetails;
