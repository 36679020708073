import React from "react";
import { Link } from "react-router-dom";
import insta from "../assets/insta.svg";
import fb from "../assets/Fb.svg";
import tw from "../assets/tweter.svg";
import li from "../assets/linkedIn.svg";
import yt from "../assets/youtube.svg";
import logo from "../assets/logo.png";
import phone from "../assets/phone-solid.svg";
import email from "../assets/mail-outline.svg";
import location from "../assets/location.svg";
import iphone from "../assets/iPhone.png";
import google from "../assets/googlePlay.webp";
import appStore from "../assets/AppStore.webp";

const Footer = () => {
  return (
    <div className="main_footer">
      <div className="footer">
        <div className="container">
          <div className="main_container">
            <div className="f_sec">
              <div className="f_sec_1">
                <img src={logo} alt="" />
                <ul>
                  <li>
                    <Link to="/#">
                      <img src={insta} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={fb} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={tw} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={li} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={yt} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="f_sec_2">
                <ul>
                  <li>Menu</li>
                  <li>
                    <Link to="/privacy-policy">Privacy policy</Link>
                  </li>
                  <li>
                    <Link to={`#`}>Terms of use</Link>
                  </li>
                  <li>
                    <Link to="contact">Contact us</Link>
                  </li>
                </ul>
              </div>
              <div className="f_sec_3">
                <ul>
                  <li>Contact</li>
                  <li>
                    <img src={phone} alt="" />
                    <span>+351 258 7702</span>
                  </li>
                  <li>
                    <img src={email} alt="" />
                    <span>lmpatente2021@gmail.com</span>
                  </li>
                  <li>
                    <img src={location} alt="" />
                    <span>Via-w.goethe 9, Bologna,40128, Italy</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="f_sec_4">
              <img src={iphone} alt="" />
              <span>DOWNLOAD THE APP</span>
              <Link
                to="https://play.google.com/store/apps/details?id=com.lmpatente"
                target="_blank"
              >
                <img src={google} alt="" />
              </Link>
              <Link to="#" target="_blank">
                <img src={appStore} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="Copyright">
        <span>Copyright © LM Patente | All rights reserved</span>
      </div>
    </div>
  );
};

export default Footer;
