import React, { useEffect, useState } from "react";
import BackButton from "../components/microComponents/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import CourseApi from "../api/services/CourseApi";
import QuizCard from "../components/QuizCard/QuizCard";
import useCourseStore from "../stores/useCourseStore";
import Urls from "../api/Urls";
import scrollToTop from "../components/ScrollTop/ScrollTop";
import Utils from "../utils/Utils";
import Skeleton from "react-loading-skeleton";
import ZoomModal from "../components/microComponents/ZoomModal";

let currentAudio: any = null;

const Quizzes = () => {
  const navigate = useNavigate();
  const { id, title } = useParams<{ id: string; title: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [quizes, setQuizes] = useState<any[]>([]);
  const setOriginalQuizList = useCourseStore(
    (state) => state.setOriginalQuizList
  );
  const setQuizList = useCourseStore((state) => state.setQuizList);

  useEffect(() => {
    scrollToTop();

    getquizzes();
  }, []);

  const getquizzes = () => {
    setIsLoading(true);

    CourseApi.getQuizzes(Number(id), {
      onSuccess: (response) => {
        const formatedQuize = response.data.reduce(
          (result: any[], item: any) => [
            ...result,
            { ...item, answer: item.answer == "1" ? true : false },
          ],
          []
        );
        // console.log("formatedQuize", formatedQuize);
        setQuizes(formatedQuize);
        const suffleQuizes = Utils.shuffle([...formatedQuize]);
        setOriginalQuizList(suffleQuizes);
        setQuizList(JSON.parse(JSON.stringify(suffleQuizes)));
        setIsLoading(false);
      },
      onError: () => setIsLoading(false),
    });
  };

  const playAudio = (playUrl: string) => {
    if (currentAudio) {
      currentAudio.pause();
    }

    // console.log("audioUrl", Urls.storagePath + playUrl);
    const audio = new Audio(Urls.storagePath + playUrl);
    audio.play();
    currentAudio = audio;
  };

  const skeleton = () => (
    <div className="stadaContainer mb-5 skeleton">
      {Array.from({ length: 10 }).map((_, index) => (
        <div key={index} className="stradaItem">
          <div className="upperSec">
            <Skeleton width={35} height={35} />
            {/* <div className="count">
              <Skeleton width={35} height={35} />
            </div> */}
            <div className="actions">
              <div className="buttons">
                <Skeleton width={50} height={20} />
                <Skeleton width={50} height={20} />
              </div>
              <span className="icon">
                <Skeleton circle={true} height={30} width={30} />
              </span>
              <div className="img">
                <Skeleton circle={true} height={30} width={30} />
              </div>
            </div>
          </div>
          <div className="info">
            <Skeleton width="85%" height={20} />
            <div className="answer">
              <Skeleton width={35} height={35} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  // console.log("quizes", quizes);
  return (
    <div className="Container pb-4">
      <div className="stradaDetailsMain">
        <div className="titleAreaStrada">
          <BackButton title={title} />
        </div>
        {quizes?.length > 0 && quizes[0].media && (
          <div className="imageA">
            <ZoomModal img={Urls.storagePath + quizes[0].media?.file_path}>
              <img src={Urls.storagePath + quizes[0].media?.file_path} alt="" />
            </ZoomModal>
            <span>Fig:01</span>
          </div>
        )}

        {isLoading ? (
          skeleton()
        ) : (
          <div className="stadaContainer">
            {quizes.map((item, index) => (
              <QuizCard
                key={item.id}
                quizNo={item.id}
                sectionId={item.section_id}
                question={item.question}
                answer={item.answer}
                // imageURL={item?.media?.file_path}
                audio={item.quiz_audio}
                quizVoice={item.quiz_voices}
                playAudio={playAudio}
              />
            ))}
          </div>
        )}

        <div className="sButton">
          <button
            onClick={() => navigate(`/quizExam/CUSTOM`)}
            className="btnr primary"
          >
            Esercitati
          </button>
        </div>
      </div>
    </div>
  );
};

export default Quizzes;
