import React from "react";
import Utils from "../../utils/Utils";
import CourseApi from "../../api/services/CourseApi";
import ModalReact from "../microComponents/ModalReact";

export interface Props {
  question: string;
  isSpace?: boolean;
}

export const QuestionSpan: React.FC<Props> = (props) => {
  const [showTranslatePopover, setShowTranslatePopover] = React.useState(false);
  const [showIndicator, setShowIndicator] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [translateText, setTranslateText] = React.useState("");
  const [imageUrl, setImgeUrl] = React.useState("");

  const { isSpace = true } = props;

  if (showTranslatePopover) {
    setTimeout(() => {
      setShowTranslatePopover(false);
    }, 3000);
  }
  // console.log(showIndicator, "show indicatior");
  const wordTranslateFromApi = async (text: string) => {
    setShowIndicator(true);
    try {
      CourseApi.getTranlation(text, {
        hideToast: true,
        onSuccess: (response) => {
          // console.log("getTranlation response", response);
          const translatedText = response.data?.meaning;
          setShowIndicator(false);
          setTranslateText(translatedText);
          setImgeUrl(response.data?.image);
          setShow(true);
        },
        onError: async () => {
          const translatedText = await Utils.translateApi(text, "bn");
          setShowIndicator(false);
          setTranslateText(translatedText);
          setImgeUrl("");
          setShowTranslatePopover(true);
        },
      });
    } catch (error) {
      setShowIndicator(false);
      console.error("Translation error:", error);
    }
  };

  return (
    <>
      <span
        // style={{border: "1px solid red"}}
        onClick={(event) => {
          wordTranslateFromApi(props.question);
        }}
        className="indicator"
      >
        {`${props.question}`}{" "} {isSpace && "\u00A0"}
        {showIndicator && (
          <span className="indicator_sm spinnerWrapper">
            <div className="spinner"></div>
          </span>
        )}
        {showTranslatePopover && <span className="text">{translateText}</span>}
      </span>

      <ModalReact
        show={show}
        onHide={() => setShow(false)}
        translate={translateText}
        imageUrl={imageUrl}
      />
    </>
  );
};

export default QuestionSpan;
