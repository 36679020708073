import ky from "ky";
import Urls from "./Urls";
import { errorToast, successToast } from "../utils/Toasts";
import { unstable_batchedUpdates } from "react-dom";
import useAppStore from "../stores/useAppStore";
const abortController = new AbortController();

export const SUCCESS = "success";
export const ERROR = "error";
export const UNAUTHORIZED = "unauthenticated";
export const VALIDATIONERROR = "ValidationError";

const extendedKy = ky.extend({
  prefixUrl: Urls.baseUrl,
  hooks: {
    beforeRequest: [
      (request) => {
        // Do something before every request
        // This is a good place to authorize request if needed
        // request.headers.set("Content-Type", "application/json");
        request.headers.set("Authorization", `Bearer ${window.accessToken}`);
      },
    ],
    beforeError: [
      // error => {
      //   const {response} = error;
      //   console.log('error response-------->', error);
      //   if (response && response.body) {
      //     error.name = 'GitHubError';
      //     error.message = `${response.body.message} (${response.status})`;
      //   }
      //   return error;
      // },
    ],
    afterResponse: [
      // async (request, options, response) => {
      //   // console.log('response.status', response.status);
      //   if (
      //     window.accessToken &&
      //     window.accessToken != '' &&
      //     response.status === 401
      //   ) {
      //     // Refresh the token and retry the request
      //     await refreshToken();
      //     // Clone the original request and update the headers with the new access token
      //     const modifiedRequest = new Request(request);
      //     modifiedRequest.headers.set(
      //       'Authorization',
      //       `Bearer ${window.accessToken}`,
      //     );
      //     return ky(modifiedRequest);
      //   }
      //   return response;
      // },
    ],
  },
  // retry: {
  //   statusCodes: [401],
  // },
  timeout: 60000,
  signal: abortController.signal,
});

export type ClientOption = {
  showIndicator?: boolean;
  hideToast?: boolean;
  onSuccess?: (response: any) => void;
  onError?: (errors: any) => void;
};
// console.log(window.accessToken);
const Client = async (
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | "FILE",
  url: string,
  params?: any,
  options?: ClientOption
) => {
  try {
    console.log("URL ==>", Urls.baseUrl + url);
    console.log("Payload ==>", url, params);

    if (options?.showIndicator) {
      // console.log('handle show indicator');
      unstable_batchedUpdates(() => useAppStore.getState().showIndicator());
    }

    let response: any;

    //handle different request method
    if (method === "GET") {
      response = await extendedKy.get(url).json();
    } else if (method === "POST") {
      response = await extendedKy.post(url, { json: params }).json();
    } else if (method === "FILE") {
      response = await extendedKy.post(url, { body: params }).json();
    } else if (method === "PUT") {
      response = await extendedKy.put(url, { json: params }).json();
    } else if (method === "PATCH") {
      response = await extendedKy.patch(url, { json: params }).json();
    } else if (method === "DELETE") {
      response = await extendedKy.delete(url, { json: params }).json();
    }

    // console.log('clientResponse', response);
    if (options?.showIndicator) {
      // console.log('handle hide indicator');
      setTimeout(() => {
        unstable_batchedUpdates(() => useAppStore.getState().hideIndicator());
      }, 75);
    }

    //checking resposone status
    if (response?.status == SUCCESS) {
      if (options?.onSuccess) {
        //console.log('onSuccess response ==>> ', response);
        options.onSuccess(response);
      }

      // console.log('Sucess response--->', response);
      return response;
    } else {
      // if status is fail and have some message then show toast text
      if (!options?.hideToast && response?.message) {
        successToast(response?.message);
      }

      //called error callback with error response
      if (options?.onError) {
        console.log("onError response ==>> ", response);
        options.onError(response);
      }

      return response;
    }
  } catch (error: any) {
    if (options?.showIndicator) {
      // console.log('handle hide indicator');
      unstable_batchedUpdates(() => useAppStore.getState().hideIndicator());
    }

    let errorData: any;

    if (error.response) {
      try {
        errorData = await error.response.json();
      } catch (e) {
        console.error("couldn't parse error response for specific message", e);
      }
    }

    // console.log("clientError", errorData);

    //start if have some message then show toast text
    if (!options?.hideToast && errorData?.message) {
      let errMsg = errorData?.message;
      if (errMsg === UNAUTHORIZED) {
        errMsg = "Session expired. Login again to continue";
      }
      errorToast(errMsg);
    }
    //end show toast text code

    if (options?.onError) {
      options.onError(errorData);
    }

    // if (error?.response?.status == 401) {
    //   Constant.logoutUser();
    // }

    return errorData;
  }
};

export default Client;
