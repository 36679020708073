import React, { useState } from "react";
import userAvater from "../assets/user.png";
import upload from "../assets/uploadImg.svg";
import PhoneInput from "react-phone-number-input";
import BackButton from "../components/microComponents/BackButton";
import useUserStore from "../stores/useUserStore";
import Urls from "../api/Urls";
import ReactSelect from "react-select";
//@ts-ignore
import countries from "react-select-country-list";
import Utils from "../utils/Utils";
import { Modal } from "react-bootstrap";
import DragDropProfile from "../components/DragAndDrop/DragDropProfile";

const MyProfile = () => {
  const user = useUserStore((state) => state.user);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>("");
  const [editable, setEditable] = useState(true);
  const [country, setCountry] = useState();
  const [showModal, setShowModal] = useState(false);
  const options = countries().getData();

  const updateProcess = (e: any) => {
    e.preventDefault();
    if (editable) {
      setEditable(false);
    } else {
      console.log("update profile");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="Container pb-4">
      <div className="profile_main">
        <div className="profile_form" onSubmit={updateProcess}>
          <div className="upperSec">
            <BackButton title="My Profile" />
            <span className="edit" onClick={updateProcess}>
              Edit Profile
            </span>
          </div>
          <div className="profilePic">
            <div className="pic">
              <img
                className="img"
                src={
                  user?.profile_pic
                    ? Urls.storagePath + user?.profile_pic
                    : userAvater
                }
                alt="user profile"
              />
              <img
                src={upload}
                className="icon"
                alt=""
                onClick={() => setShowModal(true)}
              />
            </div>
          </div>
          <div className="inputs w-100">
            <div className="input">
              <label>Full Name</label>
              <input readOnly={editable} type="text" value={user?.name} />
            </div>
            {editable && (
              <div className="input">
                <label>Phone Number</label>
                <PhoneInput
                  international
                  defaultCountry={user?.country}
                  value={user?.phone_number}
                  onChange={(value) => setPhoneNumber(value)}
                />
              </div>
            )}
            <div className="input">
              <label>Date of birth</label>
              <input type="date" value={user?.dob} readOnly={editable} />
            </div>
            <div className="input">
              <label>Address</label>
              <input type="text" value={user?.address} readOnly={editable} />
            </div>
            <div className="input">
              <label>City</label>
              <input type="text" value={user?.city} readOnly={editable} />
            </div>
            <div className="input">
              <label>Postcode</label>
              <input type="text" value={user?.post_code} readOnly={editable} />
            </div>
            <div className="input">
              <label>Country</label>
              {!editable ? (
                <ReactSelect
                  className="select"
                  options={options}
                  defaultValue={options.find(
                    (option: any) => option.value === user?.country
                  )}
                  onChange={(selectedOption) =>
                    setCountry(selectedOption?.value || "")
                  }
                />
              ) : (
                <input
                  type="text"
                  value={Utils.getCountryNameByCountryCode(user?.country)}
                />
              )}
            </div>
          </div>
          {!editable && (
            <div className="buttons w-100">
              <button
                className={`btnr ${editable ? "secoundary" : "primary"} w-100`}
              >
                Edit Profile
              </button>
            </div>
          )}
        </div>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Upload Profile Pic
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DragDropProfile closeModal={() => setShowModal(false)} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default MyProfile;
