import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main";
import PrivateRoute from "./PrivateRoute";
import Home from "../Pages/Home";
import Chapters from "../Pages/Chapters";
import Login from "../Pages/Login";
import Quizzes from "../Pages/Quizzes";
import NotFound from "../Pages/not-found";
import ChapterDetails from "../Pages/ChapterDetails";
import SectionDetails from "../Pages/SectionDetails";
import ChaptersSelection from "../Pages/ChaptersSelection";
import Statistiche from "../Pages/Statistiche";
import Signup from "../Pages/Signup";
import MyProfile from "../Pages/MyProfile";
import QuizExam from "../Pages/QuizExam";
import Functionality from "../Pages/Functionality";
import AboutUs from "../Pages/AboutUs";
import WharWeDo from "../Pages/WharWeDo";
import Forget from "../Pages/Forget";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import ContactUs from "../Pages/ContactUs";
import ImportentNotes from "../Pages/ImportentNotes";

export const route = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/chapters/:id",
        element: <Chapters />,
      },
      {
        path: "/ChapterDetails/:id",
        element: <ChapterDetails />,
      },
      {
        path: "/section/:id",
        element: <SectionDetails />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/forget",
        element: <Forget />,
      },
      {
        path: "/myProfile",
        element: (
          <PrivateRoute>
            <MyProfile />
          </PrivateRoute>
        ),
      },
      {
        path: "/quizExam/:type",
        element: (
          <PrivateRoute>
            <QuizExam />
          </PrivateRoute>
        ),
      },
      {
        path: "/quizzes/:id/:title",
        element: <Quizzes />,
      },
      {
        path: "/ChaptersSelection/:id",
        element: (
          <PrivateRoute>
            <ChaptersSelection />
          </PrivateRoute>
        ),
      },
      {
        path: "/statistiche",
        element: (
          <PrivateRoute>
            <Statistiche />
          </PrivateRoute>
        ),
      },
      {
        path: "/functionality",
        element: <Functionality />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/whatWeDo",
        element: <WharWeDo />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/contact",
        element: <ContactUs />,
      },
      {
        path: "/importent-notes",
        element: <ImportentNotes />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
