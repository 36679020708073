import React, { useEffect, useState } from "react";
import book from "../assets/lezione.svg";
import { Link, useParams } from "react-router-dom";
import BackButton from "../components/microComponents/BackButton";
import CourseApi from "../api/services/CourseApi";
import Urls from "../api/Urls";
import dummy from "../assets/dummy.jpeg";
import useCourseStore from "../stores/useCourseStore";
import Skeleton from "react-loading-skeleton";
import scrollToTop from "../components/ScrollTop/ScrollTop";

const skeleton = (
  <>
    <div className="ManualConQuiz">
      {Array.from({ length: 12 }).map((_, index) => (
        <div className="item_col" key={index}>
          <div className="ManualConQuiz_item">
            <Skeleton height={80} width={80} />

            <div className="info">
              <div className="image">
                <Skeleton circle={true} height={16} width={16} />
                <Skeleton height={16} width={20} />
              </div>
              <span className="item_name" style={{ width: "100%" }}>
                <Skeleton />
                <Skeleton width={150} />
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
);

const Chapters = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  // const [chapters, setChapters] = useState<any[]>([]);
  const chapters = useCourseStore((state) => state.chapters);
  const setChapters = useCourseStore((state) => state.setChapters);
  const setSections = useCourseStore((state) => state.setSections);

  useEffect(() => {
    scrollToTop();
    getChapters();
  }, []);

  const getChapters = () => {
    setIsLoading(true);
    CourseApi.getChapters(Number(id), {
      // showIndicator: true,
      onSuccess: (response) => {
        setIsLoading(false);
        setChapters(response.data);
      },
      onError: () => setIsLoading(false),
    });
  };

  // console.log("chapters", chapters);
  return (
    <div className="Container pb-4">
      <div className="ManualConQuiz_main">
        <BackButton title={"Chapters"} isSticky={true} />
        {isLoading && chapters.length === 0 ? (
          skeleton
        ) : (
          <div className="ManualConQuiz">
            {chapters.map((item) => (
              <div className="item_col" key={item.id}>
                <Link
                  to={`/ChapterDetails/${item.id}`}
                  state={{
                    chapterName: item?.chapter_name_italian,
                    introduction: item?.introduction,
                  }}
                  onClick={() => setSections([])}
                  className="ManualConQuiz_item"
                >
                  <img
                    className="img"
                    src={
                      item?.media?.file_path
                        ? Urls.storagePath + item?.media?.file_path
                        : dummy
                    }
                    alt="back arrow"
                  />
                  <div className="info">
                    <div className="image">
                      <img src={book} width={16} height={16} alt="book icon" />
                      <span>{item?.id}</span>
                    </div>
                    <span className="item_name">
                      {item?.chapter_name_italian}
                    </span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Chapters;
