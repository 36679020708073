import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import BackButton from "../components/microComponents/BackButton";
import useUserStore from "../stores/useUserStore";
import CourseApi from "../api/services/CourseApi";
import Urls from "../api/Urls";
import Skeleton from "react-loading-skeleton";

const Statistiche = () => {
  const isLogedIn = useUserStore((state) => state.isLogedIn);
  const [isLoading, setIsLoading] = useState(false);
  const [chapters, setChapters] = useState<any[]>([]);

  useEffect(() => {
    getChapters();
  }, []);

  const getChapters = () => {
    setIsLoading(true);

    CourseApi.getStatictics({
      onSuccess: (response) => {
        setIsLoading(false);
        setChapters(response.data);
      },
      onError: () => setIsLoading(false),
    });
  };

  const resetAll = () => {
    const confirmed = window.confirm(
      "Reset statistiche?\nThis request will permanently delete your statistical record. Are you sure you want to reset all your statistical data?"
    );

    if (confirmed) {
      CourseApi.resetStatictics({
        onSuccess: (response) => {
          setChapters([]);
        },
        onError: (error) => console.log(error),
      });
    }
  };

  const reset = (id: number) => {
    const confirmed = window.confirm(
      "Reset statistiche?\nThis request will permanently delete your statistical record. Are you sure you want to reset all your statistical data?"
    );
    if (confirmed) {
      console.log("confirmed");
    }
  };


  const loading_skeleton = (
    <Row className="statistiche skeleton">
      {Array(4).map((_, index) => (
        <Col className="item_col" xs={12} md={6} key={index}>
          <div className="statistiche_item">
            <div className="reset" onClick={() => reset(index)}>
              Reset
            </div>
            <div className="statistiche_upper">

              <div className="img">
                <Skeleton height={100} />
              </div>
              <div className="info">
                <div className="image">
                  {/* Skeleton for chapter name and ID */}
                  <Skeleton width={100} />
                </div>
                <Skeleton width={200} />
              </div>
            </div>
            <div className="statistiche_bottom">
              <div className="schoreBox">
                <div className="schore">
                  <div className="sItem black">
                    <Skeleton width={80} />
                    <Skeleton width={120} />
                  </div>
                  <div className="sItem green">
                    <Skeleton width={80} />
                    <Skeleton width={120} />
                  </div>
                  <div className="sItem red">
                    <Skeleton width={80} />
                    <Skeleton width={120} />
                  </div>
                  <div className="sItem orange">
                    <Skeleton width={80} />
                    <Skeleton width={120} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );




  return (
    <div className="Container pb-4">
      <div className="statistiche_main">
        <div className="titleSec">
          <BackButton title={"Statistiche"} />
          <button className="btnr sm warning" onClick={resetAll}>
            Reset-All
          </button>
        </div>
        {
          !isLoading ?
            (loading_skeleton)
            :
            (
              <Row className="statistiche">
                {chapters.map((item) => (
                  <Col className="item_col" xs={12} md={6} key={item.id}>
                    <div className="statistiche_item">
                      <div className="reset" onClick={() => reset(item.id)}>
                        Reset
                      </div>
                      <div className="statistiche_upper">
                        <div className="img">
                          <img
                            className=""
                            src={Urls.storagePath + item?.media?.file_path}
                            alt="back arrow"
                          />
                        </div>
                        <div className="info">
                          <div className="image">
                            <span>Capitalo</span>
                            <span>{item.id}</span>
                          </div>
                          <span className="item_name">
                            {item.chapter_name_italian}
                          </span>
                        </div>
                      </div>
                      <div className="statistiche_bottom">
                        <div className="schoreBox">
                          <div className="schore">
                            <div className="sItem black">
                              <span className="numb">{item.total_quiz}</span>
                              <span className="text">Errori da ripassare</span>
                            </div>
                            <div className="sItem green">
                              <span className="numb">{item.total_correct}</span>
                              <span className="text">Risposte non date</span>
                            </div>
                            <div className="sItem red">
                              <span className="numb">{item.total_error}</span>
                              <span className="text">Risposte non date</span>
                            </div>
                            <div className="sItem orange">
                              <span className="numb">{item.not_answer}</span>
                              <span className="text">Risposte non date</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            )
        }

      </div>
    </div>
  );
};

export default Statistiche;
