import book from "../../assets/lezione.svg";
import { ImCheckboxUnchecked } from "react-icons/im";
import { FaCheckSquare } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

export interface Props {
  imageSource: string;
  id: number;
  name: string;
  isSelected: boolean;
  onCheckBoxClicked: () => void;
  isDisabled?: boolean;
}

/**
 * This function returns the {@link ChapterSelectionCard} `component`
 *
 * @param props recieve `Props`
 * @returns `Element`
 */
export const ChapterSelectionCard: React.FC<Props> = (props) => {
  const {
    imageSource,
    id,
    name,
    isSelected,
    onCheckBoxClicked,
    isDisabled = false,
  } = props;

  // console.log('imageSource', imageSource);
  return (
    <div className="agromento_item" onClick={onCheckBoxClicked}>
      <div className="img">
        <img src={imageSource} alt="" />
      </div>
      <div className="info">
        <div className="info_count">
          <img src={book} alt="" />
          <span>{id}</span>
        </div>
        <span>{name}</span>
      </div>
      <div className="Check_input">
        {isSelected ? <FaCheckSquare /> : <ImCheckboxUnchecked />}
      </div>
    </div>
  );
};

export const ChapterSelectionCardSkeleton = () => {
  return (
    <>
      {[...Array(12)].map((_, index) => (
        <div className="agromento_item" key={index}>
          <div className="img">
            <Skeleton width={90} height={90}/>
          </div>
          <div className="info">
            <div className="info_count">
              <Skeleton width={20} height={20} circle={true}/>
              <Skeleton width={20} height={20} />
            </div>
            <Skeleton />
            <Skeleton width="50%" />
          </div>
          <div className="Check_input">
            <Skeleton width={20} height={20}/>
          </div>
        </div>
      ))}
    </>
  );
};

export default ChapterSelectionCard;
