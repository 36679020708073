import React, { useState, useEffect } from "react";
import arrow from "../../assets/arrow.svg";
import { useNavigate } from "react-router-dom";// Assuming your CSS file is named BackButton.css

interface Props {
  title: string | undefined;
  isSticky?: boolean;
}

const BackButton: React.FC<Props> = ({ title, isSticky = true }) => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const backButton = document.querySelector('.back_btn') as HTMLElement | null;
      if (backButton) {
        const backButtonPosition = backButton.getBoundingClientRect().top;
        const isScrolled = backButtonPosition <= 0;
        setScrolled(isScrolled);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      onClick={() => navigate(-1)}
      className={`back_btn ${isSticky ? "sticky" : ""} ${scrolled ? 'scrolled' : ''}`}
    >
      <img src={arrow} alt="back arrow" />
      <span>{title}</span>
    </div>
  );
};

export default BackButton;
