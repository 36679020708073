import React from "react";
import ans_vero from "../../assets/ans_vero.png";
import ans_vero_check from "../../assets/ans_vero_check.png";
import ans_vero_right from "../../assets/ans_vero_right.png";
import ans_vero_wrong from "../../assets/ans_vero_wrong.png";
import ans_falso from "../../assets/ans_falso.png";
import ans_falso_check from "../../assets/ans_falso_check.png";
import ans_falso_right from "../../assets/ans_falso_right.png";
import ans_falso_wrong from "../../assets/ans_falso_wrong.png";

export interface Props {
  type: "VERO" | "FALSO";
  examType: "SIMULATION" | "OTHERS";
  mood: "SELECTION" | "ANSWERED";
  userAnswer: boolean | undefined;
  ignoreSelection: boolean;
  correctAnswer: boolean;
  onClick: () => void;
}

export const AnswerBtn: React.FC<Props> = (props) => {
  const {
    type,
    examType,
    mood,
    userAnswer,
    ignoreSelection,
    correctAnswer,
    onClick,
  } = props;

  const getImage = () => {
    if (examType == "SIMULATION") {
      if (type == "VERO") {
        if (ignoreSelection) {
          return ans_vero;
        } else if (mood == "SELECTION") {
          if (userAnswer == undefined) {
            return ans_vero;
          } else {
            return ans_vero_check;
          }
        } else {
          if (userAnswer && correctAnswer) {
            return ans_vero_right;
          } else {
            return ans_vero_wrong;
          }
        }
      } else {
        if (ignoreSelection) {
          return ans_falso;
        } else if (mood == "SELECTION") {
          if (userAnswer == undefined) {
            return ans_falso;
          } else {
            return ans_falso_check;
          }
        } else {
          if (!userAnswer && !correctAnswer) {
            return ans_falso_right;
          } else {
            return ans_falso_wrong;
          }
        }
      }
    } else {
      if (type == "VERO") {
        if (userAnswer == undefined || ignoreSelection) {
          return ans_vero;
        } else {
          if (userAnswer && correctAnswer) {
            return ans_vero_right;
          } else {
            return ans_vero_wrong;
          }
        }
      } else {
        if (userAnswer == undefined || ignoreSelection) {
          return ans_falso;
        } else {
          if (!userAnswer && !correctAnswer) {
            return ans_falso_right;
          } else {
            return ans_falso_wrong;
          }
        }
      }
    }
  };

  // console.log("type", type, "ignoreSelection", ignoreSelection);
  return (
    <button disabled={mood === "ANSWERED" ? true : false} onClick={onClick}>
      {type === "VERO" ? "VERO" : "FALSO"}
      <img src={getImage()} alt="" />
    </button>
  );
};

export default AnswerBtn;
