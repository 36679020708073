import React from "react";

const AboutUs = () => {
  return (
    <div className="Container">
      <div className="functtionality_main">
        <div className="functionality">
          <h1>About Our Application and Services:</h1>
          <p>
            Our application is designed exclusively for users who are part of
            our non-profit coaching center. This includes not only employees but
            also partners and contractors associated with our organization. We
            aim to create a focused and collaborative environment tailored to
            the needs of our coaching center's community.
          </p>
          <p>
            Our app is designed for use by a diverse range of users, including
            the general public. It is accessible to anyone who wishes to utilize
            its features and services, without any specific restrictions based
            on company affiliation. Our goal is to provide a user-friendly and
            inclusive experience for individuals from various backgrounds and
            interests.
          </p>
          <p>
            Our app offers a range of features specifically designed for the
            general public. These include comprehensive learning modules,
            practice tests, and educational resources to help individuals
            enhance their driving knowledge. Additionally, we provide
            user-friendly interfaces, interactive content, and accessibility
            features to ensure that learners of all backgrounds can easily
            navigate and benefit from the platform. Our aim is to empower the
            general public with valuable information and resources to promote
            safe and informed driving practices.
          </p>
          <p>
            Users can obtain an account on our driving knowledge provider
            platform by visiting our website and navigating to the "Sign Up" or
            "Register" section. During the registration process, individuals
            will be prompted to provide essential information, including their
            name, contact details, and, if required, some basic details related
            to their driving history or preferences. After completing the
            registration form, users may need to verify their email address
            through a confirmation message sent to the provided email. Once the
            account is verified, users gain access to a wealth of driving
            knowledge resources, practice tests, and interactive modules. Our
            goal is to make the account creation process straightforward,
            ensuring that users can quickly and easily engage with our platform
            to enhance their driving skills and knowledge.
          </p>
          <p>
            our application is entirely free, and there are no charges
            associated with opening an account or accessing any features. We are
            committed to providing a free and inclusive platform for all users.
            Our goal is to make valuable information and resources accessible to
            everyone, without any financial barriers. There are no hidden fees,
            subscription costs, or premium features that require payment. Our
            focus is on delivering a positive and enriching experience for users
            without any financial obligations.
          </p>
          <h1>About Our Organization:</h1>
          <p>
            "LM Bangla Patente," a non-profit organization dedicated to
            providing free driving coaching services. Our mission is to empower
            individuals with comprehensive knowledge and skills related to
            driving in a safe and responsible manner. We offer a range of
            services, including: Free Driving Education: We provide
            comprehensive educational resources, lessons, and materials to help
            individuals understand the rules of the road, traffic signals, and
            other essential driving concepts. Practice Tests: Our platform
            offers free access to practice tests, allowing learners to assess
            their knowledge and readiness for real driving situations.
          </p>
          <p>
            Community Support: We foster a supportive community where learners
            can connect, share experiences, and seek advice from experienced
            drivers. Our forums and discussion groups are valuable resources for
            individuals on their journey to becoming competent drivers. Guidance
            for License Procedures: We assist users in understanding the
            procedures and requirements for obtaining a driver's license,
            offering guidance on the necessary steps to follow.
          </p>
          <p>
            Safety Tips and Information: We provide tips on safe driving
            practices, road safety, and the importance of adhering to traffic
            regulations.
          </p>
          <p>
            As a non-profit free driving coaching center, our primary focus is
            on making driving education accessible to all, promoting responsible
            driving behavior, and contributing to overall road safety within our
            community.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
