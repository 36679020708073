import React, { useEffect, useState } from "react";
import { route } from "./Routes/Route";
import { RouterProvider } from "react-router-dom";
import "./styles/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { ToastContainer } from "react-toastify";
import CourseApi from "./api/services/CourseApi";
import useUserStore from "./stores/useUserStore";
import Loader from "./components/microComponents/Loader";
import useAppStore from "./stores/useAppStore";
import ModalReact from "./components/microComponents/ModalReact";
import useTitle from "./Hooks/useTitle";

function App() {
  useTitle("Home");

  const indicator = useAppStore((state) => state.indicator);
  const updateUser = useUserStore((state) => state.updateUser);
  const setIsLogedIn = useUserStore((state) => state.setIsLogedIn);
  const setPatenteLessons = useUserStore((state) => state.setPatenteLessons);
  const [isLoading, setIsLoading] = useState(true);

  const showTranslatePopover = useAppStore(
    (state) => state.showTranslatePopover
  );
  const setShowTranslatePopover = useAppStore(
    (state) => state.setShowTranslatePopover
  );
  const translateText = useAppStore((state) => state.translateText);

  useEffect(() => {
    const appInfo = localStorage.getItem("appInfo");
    if (appInfo) {
      const parseAppInfo = JSON.parse(appInfo);
      window.accessToken = parseAppInfo.accessToken;
      setIsLogedIn(parseAppInfo.isLogIn);
      updateUser(parseAppInfo.userData);
    }

    fetchApiData();
  }, []);

  const fetchApiData = () => {
    CourseApi.getAllcourse({
      showIndicator: true,
      onSuccess: (respose) => {
        setPatenteLessons(respose.data[0]);
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  };

  return (
    <div className="App">

      {indicator ? <Loader /> : <RouterProvider router={route} />}

      {/* {indicator && <Loader /> }
      <RouterProvider router={route} /> */}

      <ModalReact
        show={showTranslatePopover}
        onHide={() => setShowTranslatePopover(false)}
        translate={translateText}
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
