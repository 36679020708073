import { toast, ToastOptions, Bounce } from "react-toastify";

type ToastType = "success" | "error" | "info";

const showToast = (
  type: ToastType,
  message: string,
  options?: ToastOptions
) => {
  toast[type](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce, // Provide the transition object or string here
    ...options,
  });
};

export const successToast = (message: string, options?: ToastOptions) => {
  showToast("success", message, options);
};

export const errorToast = (message: string, options?: ToastOptions) => {
  showToast("error", message, options);
};

export const infoToast = (message: string, options?: ToastOptions) => {
  showToast("info", message, options);
};
