import React, { PropsWithChildren, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useUserStore from "../stores/useUserStore";

export interface Props extends PropsWithChildren {
  children: ReactElement;
}

const PrivateRoute: React.FC<Props> = (props) => {
  const location = useLocation();
  const isLogedIn = useUserStore((state) => state.isLogedIn);

  if (isLogedIn) {
    return props.children;
  }

  return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
};

export default PrivateRoute;
