import React, { useState } from "react";
import logo from "../../assets/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { Offcanvas } from "react-bootstrap";
import useUserStore from "../../stores/useUserStore";
import avatar from "../../assets/avatar.png";
import down from "../../assets/down-arrow-icon.svg";
import user_icon from "../../assets/profile-outline.svg";
import sign_out_icon from "../../assets/sign_out.svg";
import Urls from "../../api/Urls";
import UserApi from "../../api/services/UserApi";
import { successToast } from "../../utils/Toasts";
import moment from "moment";

const Header = () => {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const updateUser = useUserStore((state) => state.updateUser);
  const setIsLogedIn = useUserStore((state) => state.setIsLogedIn);
  const [showSidebar, setShowSidebar] = useState(false);
  const isLogedIn = useUserStore((state) => state.isLogedIn);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const removeUserData = () => {
    try {
      window.accessToken = "";
      localStorage.removeItem("appInfo");
      setIsLogedIn(false);
      updateUser({});
      navigate("/");
    } catch (err) {
      console.log("Could not save credentials, " + err);
    }
  };

  const signOut = () => {
    UserApi.logout({
      onSuccess: (response) => {
        // console.log("response", response);
        successToast(response.message);
        removeUserData();
      },
      onError: (error) => {
        console.log("error", error);
        removeUserData();
      },
    });

    // console.log("signout")
    navigate("/login");
  };

  const closeCanvas = () => {
    setShowSidebar(false);
  };

  const navigateToHome = () => {
    navigate("/");
  };

  const navItems = (
    <>
      <nav>
        <NavLink
          to="/"
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
          onClick={closeCanvas}
        >
          Home
        </NavLink>
        <NavLink
          to="/functionality"
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
          onClick={closeCanvas}
        >
          Functionality
        </NavLink>
        <NavLink
          to="about-us"
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
          onClick={closeCanvas}
        >
          About us
        </NavLink>
        <NavLink
          to="/whatWeDo"
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
          onClick={closeCanvas}
        >
          What we do
        </NavLink>
      </nav>
    </>
  );

  const getMemberView = () => {
    // console.log(user?.userCourses?.status);
    if (user?.userCourses?.status === "PENDING") {
      return (
        <div className="membership">
          <span className="text">Membership status</span>
          <span className="date">{user?.userCourses?.status}</span>
        </div>
      );
    } else if (user?.userCourses?.status === "ACTIVE") {
      return (
        <div className="membership">
          <span className="text">Membership</span>
          <span className="date">
            {moment(user.userCourses.end_date).format("DD/MM/YYYY")}
          </span>
        </div>
      );
    }
  };

  // console.log(user, isLogedIn)
  return (
    <div className="main_header Container">
      <div className="header_container user_nav">
        <div className="logo">
          <div className="bar" onClick={toggleSidebar}>
            <FaBars />
          </div>
          <img src={logo} alt="logo" onClick={navigateToHome} />
        </div>
        <div className="nav_items d-none d-md-flex">{navItems}</div>
        <div className="user_on_nav">
          {getMemberView()}

          {!isLogedIn ? (
            <Link to="/login" className="btnr primary">
              Sign In
            </Link>
          ) : (
            <div className="hover_drop">
              <ul className="auth_user ">
                <li>
                  {user?.profile_pic ? (
                    <img
                      className="user_img"
                      src={
                        user?.profile_pic
                          ? Urls.storagePath + user?.profile_pic
                          : avatar
                      }
                      alt=""
                    />
                  ) : (
                    <img className="user_img" src={avatar} alt="" />
                  )}
                </li>
                <li className="user_name">
                  <span>{user?.name}</span>
                </li>
                <li>
                  <img className="down_icon" src={down} alt="" />
                </li>
              </ul>
              <div className="drom_down">
                <ul className="drop_box">
                  <li>
                    <div className="d_icons">
                      {/* <img src={user_icon} alt="" /> */}
                      {user?.profile_pic ? (
                        <img
                          src={
                            user?.profile_pic
                              ? Urls.storagePath + user?.profile_pic
                              : avatar
                          }
                          alt=""
                        />
                      ) : (
                        <img src={avatar} alt="" />
                      )}
                    </div>
                    <Link to="/myProfile">My Profile</Link>
                  </li>
                  <li>
                    <div className="sign_out">
                      <span onClick={signOut}>
                        <img className="d_icons" src={sign_out_icon} alt="" />{" "}
                        Sign Out
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>

      <Offcanvas
        show={showSidebar}
        onHide={toggleSidebar}
        placement="start"
        className="custom_offcanvas d-md-none"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="c_logo">
            <Link className="logo" to="/">
              <img alt="logo" src={logo} />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="nav_items mobile">{navItems}</div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Header;
