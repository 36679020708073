import React, { useEffect } from "react";
import phone from "../../assets/phone-solid.svg";
import email from "../../assets/mail-outline.svg";
import location from "../../assets/location.svg";
import { FaDiscord, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import scrollToTop from "../ScrollTop/ScrollTop";

const ContactInfo = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="contect_info">
      <div className="left_contect">
        <h2>Contect Information</h2>
        <p>Say something to start a live chat!</p>
      </div>
      <div className="f_sec_3">
        <ul>
          <li></li>
          <li>
            <img src={phone} alt="" />
            <span>+351 258 7702</span>
          </li>
          <li>
            <img src={email} alt="" />
            <span>lmpatente2021@gmail.com</span>
          </li>
          <li>
            <img src={location} alt="" />
            <span>Via-w.goethe 9, Bologna,40128, Italy</span>
          </li>
        </ul>
      </div>
      <div className="social_icons">
        <ul>
          <li>
            <Link to="#">
              <FaTwitter />
            </Link>
          </li>
          <li>
            <Link to="/#">
              <FaInstagram />
            </Link>
          </li>
          <li>
            <Link to="#">
              <FaDiscord />
            </Link>
          </li>
        </ul>
      </div>
      <div className="curcles">
        <span className="curcle"></span>
        <span className="curcle_big"></span>
      </div>
    </div>
  );
};

export default ContactInfo;
