import React from "react";
import PhoneInput from "react-phone-number-input";
import ContactInfo from "../components/ContactInfo/ContactInfo";
import scrollToTop from "../components/ScrollTop/ScrollTop";
import useUserStore from "../stores/useUserStore";

const ContactUs = () => {
  const user = useUserStore((state) => state.user);

  const [isLoading, setIsLoading] = React.useState(false);
  const [first_name, setFirst_name] = React.useState("");
  const [last_name, setLast_name] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("39");
  const [details, setDetails] = React.useState("");

  React.useEffect(() => {
    if (user) {
      setFirst_name(user?.first_name);
      setLast_name(user?.last_name);
      setEmail(user?.email);
      setPhone(user?.phone_number);
    }
  }, [user]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
  };

  return (
    <div className="Container">
      <div className="contectUs-main">
        <div className="center_logo">
          <h1 className="center_text">Contact Us</h1>
          <p>Any question or remarks? Just write us a message!</p>
        </div>
        <div className="contect_us">
          <ContactInfo />
          <form onSubmit={handleSubmit} className="modal_form">

            <div className="main_div">
              <div className="input_div">
                <label htmlFor="first_name">Full Name</label>
                <input
                  type="text"
                  name="first_name"
                  required
                  value={first_name}
                  onChange={(e) => setFirst_name(e.target.value)}
                  placeholder="Enter your full name"
                />

              </div>

              <div className="input_div">
                <label htmlFor="mail">Email</label>
                <input
                  required
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

              </div>
            </div>
            <div className="main_div">
              <div className="input_div">
                <label htmlFor="residence_country">Phone Number</label>
                <PhoneInput
                  international
                  defaultCountry="IT"
                  value={phone}
                  required
                  onChange={(value) => setPhone(String(value))}
                />

              </div>

            </div>
            <div className="text_area">
              <label htmlFor="discription">Discription</label>
              <textarea
                className="textareaField"
                value={details}
                placeholder="Enter your discription"
                onChange={(e) => setDetails(e.target.value)}
              ></textarea>
            </div>
            <button disabled={isLoading ? true : false} className="btnr primary w-100">
              {isLoading ? (
                <div className="loading_circle_btn"></div>
              ) : (
                "Send Message"
              )}
            </button>
          </form>
        </div>
      </div>

    </div>
  );
};

export default ContactUs;
