import React from "react";

const Functionality = () => {
  return (
    <div className="Container">
      <div className="functtionality_main">
        <div className="functionality">
          <h1>Application Functionality and Features:</h1>
          <p>
            This nonprofit educational app is designed to provide comprehensive
            driving knowledge and skills to students and individuals in a
            theoretical manner. The app aims to enhance learning through various
            levels, each offering unique features and functionalities.
          </p>
          <h5>Key Levels:</h5>
          <h6>Level-1: Home Page</h6>
          <ul>
            <li>Main Menu</li>
            <li>Learning Content Categories</li>
            <li>Learning Chapters</li>
            <li>Exam Categories</li>
            <li>Result Statistics</li>
          </ul>
          <p>
            Objective: Provide a centralized hub with easy navigation to access
            main features.
          </p>
          <h6>Level-2: Learning Content</h6>
          <ul>
            <li>Detailed Informative Content</li>
            <li>Content-related Figures</li>
            <li>Content-related Audio Sounds</li>
          </ul>
          <p>
            Objective: Direct access to diverse learning materials, including
            text, figures, and audio for effective learning.
          </p>
          <h6>Level-3: Practice Section</h6>
          <ul>
            <li>Practice Questions (MCQ Type, True/False)</li>
            <li>Group Practices for Specific Content</li>
          </ul>
          <p>
            Objective: Offer a platform for users to practice and reinforce
            their learning through interactive questions.
          </p>
          <h6>Level-4: Exam Section</h6>
          <ul>
            <li>Fixed Time MCQ Type True/False Exam</li>
            <li>Sequential Question Format</li>
          </ul>
          <p>
            Objective: Conduct structured exams with fixed time limits,
            fostering a focused and time-bound learning experience.
          </p>
          <h6>Level-5: Result Statistics</h6>
          <ul>
            <li>Detailed Exam Results</li>
          </ul>
          <p>
            Objective: Display comprehensive statistics of exam performance,
            providing feedback for improvement.
          </p>
          <h1>App Description:</h1>
          <p>
            This nonprofit educational app is a dynamic platform that delivers
            driving knowledge and skills in a theoretical format. Users can
            explore diverse learning content through detailed text, figures, and
            audio, ensuring a well-rounded educational experience. The practice
            section allows individuals to reinforce their understanding through
            interactive quizzes, while the exam section evaluates their
            knowledge with a fixed-time MCQ format. Detailed result statistics
            provide valuable insights for continuous improvement.
          </p>
          <p>
            The email ID is:{" "}
            <a href="mailto:lmpatente2021@gmail.com">lmpatente2021@gmail.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Functionality;
