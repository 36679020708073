import React, { useEffect, useState } from "react";
// import LeftArrow from "../components/Icons/LeftArrow";
// import RightArrow from "../components/Icons/RightArrow";
import BackButton from "../components/microComponents/BackButton";
import { useLocation, useParams } from "react-router-dom";
import CourseApi from "../api/services/CourseApi";
import SectionCard from "../components/SectionCard/SectionCard";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import scrollToTop from "../components/ScrollTop/ScrollTop";
import useCourseStore from "../stores/useCourseStore";

const skeleton = (
  <>
    {Array.from({ length: 5 }).map((_, index) => (
      <div className="trafficoItem skeleton">
        <div className="header">
          <div className="title">
            <Skeleton width={200} height={30} />
          </div>
          <div className="subSection">
            <div className="mainImage">
              <Skeleton width={200} height={20} />
              <Skeleton width={200} height={20} />
            </div>
          </div>
        </div>
        <div className="info">
          <Skeleton count={4} />
          <Skeleton width="50%" height={20} />
        </div>

        <div className="groupBtn">
          <Skeleton width={100} height={30} />

          <Skeleton width={100} height={30} />
        </div>
      </div>
    ))}
  </>
);

const ChapterDetails = () => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  // const [sections, setSections] = useState<any[]>([]);
  const sections = useCourseStore((state) => state.sections);
  const setSections = useCourseStore((state) => state.setSections);
  const [nextPageNo, setNextPageNo] = useState(0);

  useEffect(() => {
    scrollToTop();
    getSections();
  }, []);

  const getSections = () => {
    setIsLoading(true);
    CourseApi.getSections(Number(id), 1, {
      onSuccess: (response) => {
        // console.log("response", response);
        setIsLoading(false);
        setSections(response.data);
        setNextPageNo(response?.meta?.next_page_url ? 2 : 0);
      },
      onError: () => setIsLoading(false),
    });
  };

  const handleLoadMore = () => {
    if (!isLoading && nextPageNo > 0) {
      setIsLoading(true);

      CourseApi.getSections(Number(id), nextPageNo, {
        onSuccess: (response) => {
          // console.log("response", response);
          setIsLoading(false);
          setSections(
            response?.data ? [...sections, ...response.data] : [...sections]
          );
          setNextPageNo(response?.meta?.next_page_url ? nextPageNo + 1 : 0);
        },
        onError: () => setIsLoading(false),
      });
    }
  };

  // console.log("location", location.state);
  return (
    <div className="Container pb-4">
      <div className="trafficoMain">
        <BackButton title={location.state?.chapterName} isSticky={true} />

        {isLoading && sections.length === 0 ? (
          skeleton
        ) : (
          <InfiniteScroll
            dataLength={sections.length} // This is important field to render the next data
            next={handleLoadMore}
            hasMore={nextPageNo > 0 ? true : false}
            loader={skeleton}
            // endMessage={<h4>No more items</h4>}
          >
            {location.state?.introduction && (
              <SectionCard
                key={0}
                title={"INTRODUZIONE"}
                desc={location.state?.introduction}
                groups={[]}
              />
            )}

            {sections.map((item, index) => (
              <SectionCard
                key={item.id}
                title={item.section_title}
                desc={item.section_desc}
                groups={item.groups}
                mainImages={
                  item.mainMedia?.length > 0 ? item.mainMedia[0] : undefined
                }
                optionalImages={item.optionalMedia}
              />
            ))}
          </InfiniteScroll>
        )}

        {/* <div className="navButtons">
          <button className="btnr">
            <LeftArrow />
            <span className="d-none d-md-inline">Previous</span>
          </button>
          <button className="btnr">
            <span className="d-none d-md-inline">Next</span>
            <RightArrow />
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ChapterDetails;
