import React, { useRef, useEffect } from "react";
import upload from "../../assets/upload.svg";
import { useDropzone } from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import UserApi from "../../api/services/UserApi";
import Utils from "../../utils/Utils";

const DragDropProfile: React.FC<any> = ({ closeModal }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [cropData, setCropData] = React.useState<any>(null);
  const cropperRef = useRef<any>(null); // Adjusted type to 'any'
  const [selectedImage, setSelectedImage] = React.useState<string | null>(null);

  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      setSelectedImage(String(reader.result)); // Explicitly cast to string
    };
    reader.readAsDataURL(file);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (selectedImage && cropperRef.current) {
      const initialCropData = { width: 500, height: 500 };
      (cropperRef.current as any).cropper.setCropBoxData(initialCropData);
      (cropperRef.current as any).cropper.setData(initialCropData);
    }
  }, [selectedImage]);

  const handleCrop = () => {
    if (!cropperRef.current) {
      return;
    }
    (cropperRef.current as any).cropper
      .getCroppedCanvas()
      .toBlob((blob: any) => {
        setIsLoading(true);

        const formData = new FormData();
        formData.append("profile_pic", blob);

        UserApi.uploadProfilePic(formData, {
          onSuccess: (response) => {
            setIsLoading(false);
            Utils.saveUserCreadential(response.data, window.accessToken, true);
            closeModal();
          },
          onError: () => setIsLoading(false),
        });
      });
  };

  const cancelCrop = () => {
    setSelectedImage(null);
    setCropData(null);
  };

  return (
    <div className="drag_main">
      {!selectedImage && (
        <>
          <div
            {...getRootProps()}
            className={`upload_img profile_pic ${isDragActive ? "active" : ""}`}
          >
            <img src={upload} alt="Upload Icon" />
            <input {...getInputProps()} accept="image/*" />
            {isDragActive ? (
              <p>Drop the profile picture here</p>
            ) : (
              <p>
                Drag and drop a profile picture here, or click to select a file
              </p>
            )}
          </div>
          <button onClick={closeModal} className="btnr primary">
            Cancel
          </button>
        </>
      )}

      {selectedImage && !cropData && (
        <div className="crop_button croping_img">
          <Cropper
            ref={cropperRef}
            src={selectedImage}
            style={{ height: 400, width: "100%" }}
            aspectRatio={1}
            guides={true}
          />
          <div className="buttonsSec">
            <button
              disabled={isLoading ? true : false}
              onClick={handleCrop}
              className="btnr sm primary"
            >
              {isLoading ? <div className="spinnerWrapper"><div className="spinner white"></div></div> : "Crop and Save"}
            </button>
            <button className="btnr sm warning" onClick={cancelCrop}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DragDropProfile;
