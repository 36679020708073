import React, { useEffect, useState } from "react";
import BackButton from "../components/microComponents/BackButton";
import CourseApi from "../api/services/CourseApi";
import { useNavigate, useParams } from "react-router-dom";
import ChapterSelectionCard, {
  ChapterSelectionCardSkeleton,
} from "../components/ChapterSelectionCard/ChapterSelectionCard";
import Urls from "../api/Urls";
import useCourseStore from "../stores/useCourseStore";

const ChaptersSelection = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [chapters, setChapters] = useState<any[]>([]);
  const setCapitoliIds = useCourseStore((state) => state.setCapitoliIds);

  useEffect(() => {
    getChapters();
  }, []);

  const getChapters = () => {
    setIsLoading(true);

    CourseApi.getChapters(Number(id), {
      onSuccess: (response) => {
        setIsLoading(false);
        if (response.data?.length > 1) {
          let filterQuizList: any[] = [];
          response.data.map((item: any, index: number) => {
            let quiz = { ...item, isSelected: false };
            filterQuizList.push(quiz);
          });
          setChapters(filterQuizList);
        }
      },
      onError: () => setIsLoading(false),
    });
  };

  const onCheckBoxClicked = (value: boolean, index: number) => {
    let newArray = [...chapters];
    newArray[index].isSelected = value;
    setChapters(newArray);
  };

  const onSelectAll = () => {
    let newArray = chapters.reduce(
      (result, item) => [...result, { ...item, isSelected: true }],
      []
    );
    setChapters(newArray);
  };

  const onContinuePressed = () => {
    const selectedIds = chapters.reduce((result, item) => {
      if (item.isSelected) {
        return [...result, item.id];
      }
      return result;
    }, []);

    // if (selectedIds.length == 0) {
    //   return toast.show(t("validation.select_chapters").toString(), {
    //     type: "custom_error",
    //   });
    // }

    setCapitoliIds(selectedIds);
    navigate("/quizExam/CAPITOLI");
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const backButton = document.querySelector('.subBox') as HTMLElement | null;
      if (backButton) {
        const backButtonPosition = backButton.getBoundingClientRect().top;
        const isScrolled = backButtonPosition <= 0;
        setScrolled(isScrolled);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="Container pb-4">
      <div className="argomento_main">
        <div className={`subBox sticky ${scrolled ? 'scrolled' : ''}`}>
          <BackButton title={"Argomento"} isSticky={true} />
          <div className="selectAll">
            <button className="btnr primary" onClick={onSelectAll}>
              Select All
            </button>
          </div>
        </div>

        <div className="agomento_container">
          {isLoading ? (
            <ChapterSelectionCardSkeleton />
          ) : (
            chapters.map((item, index) => (
              <ChapterSelectionCard
                key={item.id}
                imageSource={Urls.storagePath + item?.media?.file_path}
                id={item.id}
                name={item.chapter_name_italian}
                isSelected={item.isSelected}
                onCheckBoxClicked={() =>
                  onCheckBoxClicked(!chapters[index].isSelected, index)
                }
              />
            ))
          )}
        </div>
        <div className="sButton">
          <button
            onClick={onContinuePressed}
            disabled={chapters.findIndex((item) => item.isSelected) < 0}
            className="btnr primary"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChaptersSelection;
